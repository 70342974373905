<template>
  <div class="forgot-username">
    <h2>{{ $t('forgotUsername') }}</h2>
    <p class="text-blue">
      {{ subTitle }}
    </p>
    <form>
      <div
        v-if="bothChannelsEnabled"
        class="form-check"
      >
        <input
          id="channelEmail"
          v-model="channel"
          class="form-check-input"
          type="radio"
          name="channel"
          value="email"
        >
        <label
          class="form-check-label"
          for="channelEmail"
        >{{ $tc('email', 1) }}</label>
      </div>

      <div
        v-if="bothChannelsEnabled"
        class="form-check"
      >
        <input
          id="channelMobile"
          v-model="channel"
          class="form-check-input"
          type="radio"
          name="channel"
          value="mobile"
        >
        <label
          class="form-check-label"
          for="channelMobile"
        >{{ $tc('mobileNumber', 1) }}</label>
      </div>

      <div
        v-show="channel == 'email'"
        class="form-group mt-4"
      >
        <label for="email">{{ $t("emailAdress") }}</label>
        <input
          id="email"
          v-model.trim="email"
          v-validate="'required|email'"
          type="email"
          class="form-control"
          name="email"
        >
        <span
          v-show="errors.has('email')"
          class="error"
        >{{ errors.first('email') }}</span>
      </div>

      <div
        v-show="channel == 'mobile'"
        class="form-group mt-4"
      >
        <label for="email">{{ $tc('mobileNumber', 1) }}</label>
        <input
          id="mobile"
          v-model="mobile"
          v-validate="'required|min:10|max:10|numeric'"
          type="numeric"
          class="form-control"
          name="mobile"
        >
        <span
          v-show="errors.has('mobile')"
          class="error"
        >{{ errors.first('mobile') }}</span>
      </div>

      <div class="form-group buttons pt-3">
        <button
          v-show="channel != ''"
          :disabled="errors.any() || submitting"
          type="submit"
          class="btn btn-primary btn-block"
          @click.prevent="onSubmit"
        >
          {{ $t("sendUsername") }}
        </button>
        <router-link
          :to="{ name: 'Login' }"
          tag="button"
          class="btn mt-3"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { Helpers } from '@/mixins/Helpers'
export default {
  name: 'ForgotUsername',
  mixins: [Helpers],
  data () {
    return {
      channel: '',
      email: '',
      mobile: '',
      submitting: false
    }
  },
  computed: {
    subTitle () {
      if (this.bothChannelsEnabled) {
        return this.$t('forgotUsernameTitleBoth')
      } else if (this.smsEnabled) {
        return this.$t('forgotUsernameTitleMobile')
      } else if (this.emailEnabled) {
        return this.$t('forgotUsernameTitleEmail')
      }

      return ''
    }
  },
  watch: {
    channel (newValue) {
      this.$validator.reset(newValue)
    }
  },
  created () {
    this.channel = this.bothChannelsEnabled || this.emailEnabled ? 'email' : 'mobile'
  },
  methods: {
    onSubmit () {
      this.$validator.validate(`${this.channel}`).then((valid) => {
        if (valid) {
          const loader = this.$loading.show()
          this.submitting = true
          const data = {
            channel: this.channel === 'email' ? 1 : 2,
            value: this.channel === 'email' ? this.email.toLowerCase() : this.mobile
          }
          this.$store.dispatch('RECOVER_USERNAME', data)
            .then((response) => {
              if (response && response.status !== 200) {
                this.$validator.errors.add(`${this.channel}`, response.data.message)
              } else {
                this.$toast.success({ message: this.$t('usernameSent', data) })
                this.$router.push({ name: 'Login' })
              }
            }).finally(() => {
              loader.hide()
              this.submitting = false
            })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .forgot-username {
    display: flex;
    flex-direction: column;
    h2 {
      white-space: nowrap;
    }
    form {
      flex: 1;
      display: flex;
      flex-direction: column;
      .buttons {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
      }
    }
    .form-group.m-t{
      margin-top: 45px;
    }
  }
</style>
